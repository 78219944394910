<template>
  <div class="company-setting">
    <v-tabs
      vertical
      active-class="custom-tab-active"
      v-model="settingTab"
      background-color="transparent"
      color="cyan"
      class="tab-border-right"
    >
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 85vh; position: relative"
      >
        <template v-for="(tab, index) in SettingTabs">
          <v-tab
            v-if="getPermission(tab.permission + ':view')"
            :key="index"
            :disabled="tab.disabled"
            class="font-size-16 font-weight-600 px-8"
            :href="'#' + tab.key"
          >
            {{ tab.title }}
          </v-tab>
        </template>
      </perfect-scrollbar>
      <v-tabs-items v-model="settingTab">
        <template v-for="(tab, index) in SettingTabs">
          <v-tab-item
            v-if="getPermission(tab.permission + ':view')"
            :value="tab.key"
            :key="index"
          >
            <component :is="tab.template" />
          </v-tab-item>
        </template>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CompanyTemplate from "@/view/pages/profile/Setting/Company";
import RolePermissionTemplate from "@/view/pages/profile/Setting/RolePermission";
import BrandingTemplate from "@/view/pages/profile/Setting/Branding";
import CustomerTemplate from "@/view/pages/profile/Setting/Customer";
import SupplierTemplate from "@/view/pages/profile/Setting/Supplier";
import PropertyTemplate from "@/view/pages/profile/Setting/Property";
import ProjectTemplate from "@/view/pages/profile/Setting/Project";
import ContractTemplate from "@/view/pages/profile/Setting/Contract";
import ExpenseTemplate from "@/view/pages/profile/Setting/Expense";
import TaskTemplate from "@/view/pages/profile/Setting/Task";
import LeadTemplate from "@/view/pages/profile/Setting/Lead";
import ProposalTemplate from "@/view/pages/profile/Setting/Proposal";
import PurchaseOrderTemplate from "@/view/pages/profile/Setting/Purchase-Order";
import QuotationTemplate from "@/view/pages/profile/Setting/Quotation";
import InvoiceTemplate from "@/view/pages/profile/Setting/Invoice";
import PaymentTemplate from "@/view/pages/profile/Setting/Payment";
import JobTemplate from "@/view/pages/profile/Setting/Job";
import EngineerTemplate from "@/view/pages/profile/Setting/Engineer";
import ProductTemplate from "@/view/pages/profile/Setting/Product";
import ServiceTemplate from "@/view/pages/profile/Setting/Service";
import EquipmentTemplate from "@/view/pages/profile/Setting/Equipment";
import TaxesTemplate from "@/view/pages/profile/Setting/Taxes";
import CalendarTemplate from "@/view/pages/profile/Setting/Calendar";
import IntegrationTemplate from "@/view/pages/profile/Setting/Integrate";
import EmailTemplate from "@/view/pages/profile/Setting/EmailTemplate";

export default {
  name: "profile-setting",
  data() {
    return {
      SettingTabs: [
        {
          disabled: false,
          key: "company",
          template: CompanyTemplate,
          title: "Company",
          permission: "setting"
        },
        {
          disabled: false,
          key: "role-permission",
          template: RolePermissionTemplate,
          title: "Role & Permission",
          permission: "setting"
        },
        {
          disabled: false,
          key: "branding",
          template: BrandingTemplate,
          title: "Branding",
          permission: "setting"
        },
        {
          disabled: false,
          key: "customer",
          template: CustomerTemplate,
          title: "Customer",
          permission: "setting"
        },
        {
          disabled: false,
          key: "supplier",
          template: SupplierTemplate,
          title: "Supplier",
          permission: "setting"
        },
        {
          disabled: false,
          key: "property",
          template: PropertyTemplate,
          title: "Property",
          permission: "setting"
        },
        {
          disabled: false,
          key: "quotation",
          template: QuotationTemplate,
          title: "Quotation",
          permission: "setting"
        },
        {
          disabled: false,
          key: "project",
          template: ProjectTemplate,
          title: "Project",
          permission: "setting"
        },
        {
          disabled: false,
          key: "contract",
          template: ContractTemplate,
          title: "Contract",
          permission: "setting"
        },
        {
          disabled: false,
          key: "expense",
          template: ExpenseTemplate,
          title: "Expense",
          permission: "setting"
        },
        {
          disabled: false,
          key: "task",
          template: TaskTemplate,
          title: "Task",
          permission: "setting"
        },
        {
          disabled: false,
          key: "lead",
          template: LeadTemplate,
          title: "Lead",
          permission: "setting"
        },
        {
          disabled: false,
          key: "proposal",
          template: ProposalTemplate,
          title: "Proposal",
          permission: "setting"
        },
        {
          disabled: false,
          key: "purchase-order",
          template: PurchaseOrderTemplate,
          title: "Purchase Order",
          permission: "setting"
        },
        {
          disabled: false,
          key: "invoice",
          template: InvoiceTemplate,
          title: "Invoice",
          permission: "setting"
        },
        {
          disabled: false,
          key: "payment",
          template: PaymentTemplate,
          title: "Payment",
          permission: "setting"
        },
        {
          disabled: false,
          key: "job",
          template: JobTemplate,
          title: "Job",
          permission: "setting"
        },
        {
          disabled: false,
          key: "engineer",
          template: EngineerTemplate,
          title: "Engineer",
          permission: "setting"
        },
        {
          disabled: false,
          key: "product",
          template: ProductTemplate,
          title: "Product",
          permission: "setting"
        },
        {
          disabled: false,
          key: "service",
          template: ServiceTemplate,
          title: "Service",
          permission: "setting"
        },
        {
          disabled: false,
          key: "equipment",
          template: EquipmentTemplate,
          title: "Equipment",
          permission: "setting"
        },
        {
          disabled: false,
          key: "taxes",
          template: TaxesTemplate,
          title: "Taxes",
          permission: "setting"
        },
        {
          disabled: false,
          key: "calendar",
          template: CalendarTemplate,
          title: "Calendar",
          permission: "setting"
        },
        {
          disabled: false,
          key: "email-template",
          template: EmailTemplate,
          title: "Email Template",
          permission: "setting"
        },
        {
          disabled: false,
          key: "integration",
          template: IntegrationTemplate,
          title: "Integrations",
          permission: "dashboard"
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Profile" },
      { title: "Setting" }
    ]);

    if (this.$route.query && this.$route.query.tab) {
      this.settingTab = this.$route.query.tab;
    }
  },
  computed: {
    settingTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.settingTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "company";
      }
    }
  }
};
</script>
